body {
  font-family: 'Unna-Regular'
}

/* Header css */
.navbar {
  background: #094709;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: white !important;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 20px;
  padding-left: 0;
  list-style: none;
  text-align: center;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 20px;
}

.nav-links:hover {
  background-color: #094709;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 50px !important;
  line-height: 4 !important;
  font-weight: 500;
  opacity: 2 !important;
  font-family: monospace !important;
  top: -160px;
    position: relative;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.slick-next:before {
  content: '>' !important;
}

.slick-prev:before {
  content: "<" !important;
}

/* banner css*/
.banner-main-div .slider-div {
  width: 100%;
  cursor: pointer;
  padding-top: 15px;
}

.banner_main-div {
  position: relative;

}

.banner_main-div .banner_images {
  width: 100%;
  height: 550px;
}

.banner-main-div .banner-img {
  height: 550px;
  object-fit: cover;
}

.banner_content_title {
  font-size: 45px;
  font-weight: 600 !important;
}

.banner_content {
  position: relative;
  top: -110px;
  width: 60%;
  height: 200px;
  text-align: center;
  background: white;
  border-bottom: 3px solid black
}

.butn-style4 {
  color: #fff;
  padding: 17px 30px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 10px;
  border: none;
  background: #094709;
  letter-spacing: 1.4px;
  font-size: 14px;
  font-weight: bold;
}

/* organic gardening*/
.header-main-div {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  height: 80px;
  width: 100%;
  z-index: 99;
}

.header-main-div .navbar-logo {
  font-size: 25px;
  color: white;
}

.home-main-div .single-title {
  font-family: 'Unna-Regular';
  font-size: 3.5rem;
  font-weight: 600;
}

.home-main-div .content-title {
  font-family: 'Unna-Regular';
  font-size: 2rem;
  font-weight: 600;
}

.home_div {
  /* padding: 0 120px; */
  width: 100%;
}

.home-main-div .contents-div .image-div .image {
  width: 100%;
  height: 550px;
}

.home-main-div .blog-div .blog {
  font-family: ui-monospace, emoji, 'Lora';
}

.home-main-div .blog-div .blog-desc {
  font-family: 'Lato-Regular';
}

.home-main-div .content-div .image-div {
  height: 500px;
}

.home-main-div .content-div .image-div .image {
  width: 90%;
  width: 100%;
  object-fit: cover;
}

.home-main-div .contents-div {
  width: 95%;
}

.home-main-div .contents-div .blog-div ul>li {
  font-size: 1.25rem;
}

/* footer */
.footer-main-div {
  background-color: #f9f9f9;
}

/*feedBack form */
.label-name {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 100px;
}

.submit-button {
  padding: 10px 50px;
  background-color: #094709;
  color: #fff;
  border: none;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #094709;
}

form {
  display: flex;
  flex-direction: column;
  width: 650px;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px 40px 40px;
  border-radius: 6px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  background-color: #fefefe;
}

.error {
  color: red;
}

.about-us-main-div .about-content {
  line-height: 33px;
  font-size: 1.1rem;
}

.about-us-main-div .about-que {
  line-height: 38px;
}
.footer-main-div {

  background-color: #266526;
  color: #fff;
}

.footer-main-div .footer-inner-div h4 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: math;
  font-weight: 600;
}

.footer-main-div .footer-inner-div h6 {
  color: #ddffef;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.footer-main-div .second-footer-div {
  height: 100px;
  background-color: #094709;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background-color: #094709;
  color: #fff;
  position: relative;
}

.logo {
  font-size: 1.5em;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav li {
  margin: 0 10px;
}

.nav .nav-links {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}

.prompt {

  height: 50px;
  width: 350px;
  border-radius: 23px;
  padding: 0px 15px;
  background-color: rgb(245, 242, 242);
  outline: none;
  border: sienna;

}

.searchbar-div {
  position: absolute;
  z-index: 9;
  background: rgb(245, 242, 242);
  color: black;
  width: 350px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.searchbar-inner-div {
  overflow: auto;
  max-height: 365px;

}

.searchbar-inner-div::-webkit-scrollbar {
  width: 5px;
}

.searchbar-inner-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.searchbar-inner-div::-webkit-scrollbar-thumb {
  background: #888;
}

.searchbar-inner-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searchbar-div-1 {
  display: none !important;
}

.ui.search {
  text-align: center;
}

.searchbar-icon {
  font-size: 24px;
  color: black;
  right: 12px;
  position: absolute;
  top: 13px;
  cursor: pointer;
}

.ui-icon {
  position: relative;
}

.search_bar {
  display: none;
}
@media (max-width:1500px) {
  .home-main-div .contents-div .image-div .image {
    width: 100%;
  }
}

@media (max-width:1200px) {
  .home-main-div .contents-div .image-div .image {
    height: unset !important;
  }

  .header {
    justify-content: space-between;
  }
}

.footer-main-div {
  background-color: #094709;
  color: #fff;
}

.footer-main-div .footer-inner-div h4 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: math;
  font-weight: 600;
}

.footer-main-div .footer-inner-div h6 {
  color: #ddffef;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.footer-main-div .second-footer-div {
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #094709;
  color: #fff;
  position: relative;
}

.logo {
  font-size: 1.5em;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav li {
  margin: 0 10px;
}

.nav .nav-links {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
}

.prompt {

  height: 50px;
  width: 350px;
  border-radius: 23px;
  padding: 0px 15px;
  background-color: rgb(245, 242, 242);
  outline: none;
  border: sienna;

}

.searchbar-div {
  position: absolute;
  z-index: 9;
  background: rgb(245, 242, 242);
  color: black;
  width: 350px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.searchbar-inner-div {
  overflow: auto;
  max-height: 365px;

}

.searchbar-inner-div::-webkit-scrollbar {
  width: 5px;
}

.searchbar-inner-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.searchbar-inner-div::-webkit-scrollbar-thumb {
  background: #888;
}

.searchbar-inner-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searchbar-div-1 {
  display: none !important;
}

.ui.search {
  text-align: center;
}

.searchbar-icon {
  font-size: 24px;
  color: black;
  right: 12px;
  position: absolute;
  top: 13px;
  cursor: pointer;
}

.ui-icon {
  position: relative;
}

.search_bar {
  display: none;
}

/* .home-inner-div {
  height: 180vh;
  overflow-y: scroll;
} */

.home-inner-div::-webkit-scrollbar {
  width: 3px;
}

.home-inner-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.home-inner-div::-webkit-scrollbar-thumb {
  background: #888;
}

.home-inner-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.listing_div {
  /* height: 180vh;
  overflow-y: scroll; */
  background-color: #f5f2f2;
  position: relative;
  z-index: 9;
}

.listing_div::-webkit-scrollbar {
  width: 3px;
}

.listing_div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.listing_div::-webkit-scrollbar-thumb {
  background: #888;
}

.listing_div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.banner-main-div {
  max-width: 80% !important;

}

.garden-data {
  max-width: 80% !important;
}

.header_div {
  width: 90%;
}

.nav {
  margin-left: 194px;
}

.link {
  margin-bottom: 0 !important;
}

.feedback_div {
  margin-bottom: 1.5rem !important;
}

.second-footer-div {
  justify-content: space-between;
}

.not_found_page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  flex-direction: column
}
.content_404 {
  font-size: 22px;
  text-align: center;
}
.goBack_button{
   margin-top: 15px;
   width: 200px;
   height: 55px;
   background-color: #094709;
   color: white;
   font-size: 20px;
   border-radius: 10px;
   border: none;
}
.blog_404{
  font-size: 100px;
}
@media (max-width:1400px) {
  .banner-main-div {
    max-width: 100% !important;
  }

  .garden-data {
    max-width: 100% !important;
  }

  .home-main-div .contents-div .image-div .image {
    width: 100%;
  }

  .header_div {
    width: 100%;
  }

  .nav {
    margin-left: 0;
  }
}

@media (max-width:1200px) {
  .home-main-div .contents-div .image-div .image {
    height: unset !important;
  }

  .header {
    justify-content: space-between;
  }

}

@media (max-width: 960px) {
  .navbar {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    opacity: 1;
    /* transition: all 0.5s ease; */
    visibility: hidden;
  }

  .nav-menu.active {
    background: #094709;
    margin-top: 4px;
    left: 0;
    opacity: 1;
    /* transition: all 0.5s ease; */
    z-index: 1;
    padding-bottom: 15px;
    visibility: visible;
  }

  .nav-links {
    text-align: center;
    width: 100%;
  }

  .nav-links:hover {
    background-color: #094709;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .home-main-div .blog-div {
    width: 100% !important;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -7px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .home_div {
    padding: 0;
  }

  .home-main-div {
    margin-top: -40px;
    height: 100% !important;
  }

  .home-inner-div {
    padding-right: 0 !important;
  }

  .about-us-main-div .about-inner-div {
    margin: 1rem 0 !important;
    padding: 0 !important;
  }

  .about-us-main-div .about-content {
    padding: 0 15px !important;
  }

  /*home page */
  .home-main-div .contents-div {
    width: unset !important;
  }

  .home-main-div .content-div .image-div {
    height: unset !important;
    padding: 0 !important;
  }

  .home-main-div .single-title {
    font-size: 3rem !important;
  }

  /* Banner */
  .banner-main-div .slider-div {
    width: 100% !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
    line-height: 4 !important;
  }

  .listing_col {
    display: none;
  }
}

@media (max-width: 820px) {
  .header {
    justify-content: space-between;
  }
}
@media (max-width:994px) {
  .home-inner-div {
    height: 100%;
    overflow-y: unset;
  }

  .not_found_page {
    height: 23vh;
  }
}

@media (max-width: 850px) {
  .header {
    justify-content: space-between;
  }

  .nav {
    display: none !important;
    flex-direction: column;
    position: absolute;
    top: 90px;
    z-index: 9;
    left: 0;
    width: 100%;
    background-color: #094709;
    padding: 10px 0;
    margin-left: 0;
  }

  .nav--open {
    display: flex !important;
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
  }

  .nav li {
    margin: 10px 0;
  }

  .mobile-menu-toggle {
    display: flex;
  }

  .prompt {
    display: none;
  }

  .prompt1 {

    height: 50px;
    width: 100%;
    border-radius: 23px;
    padding: 0px 15px;
    background-color: white;
    outline: none;
    border: sienna;

  }
  .blog_404{
    font-size: 70px;
  }
  .search_bar {
    display: flex;
    position: absolute;
    padding: 10px 0px;
    position: absolute;
    z-index: 9;
    width: 100%;
    background: #094709;
  }

  .searchbar_div_1 {
    color: white;
    max-height: 400px;
    overflow: scroll;
  }

  .searchbar-icon {
    display: none;
  }

  .searchbar-div {
    display: none;
  }

  .banner_content_title {
    font-size: 40px;
  }

  .searchbar-div-1 {
    display: flex !important;
    font-size: 28px;
    color: white;
    position: absolute;
    top: 28px;
    right: 60px;
  }

  .ui-icon {
    position: unset;
    padding: 0 15px;
  }
  .slick-prev:before,
  .slick-next:before{
    top: -120px;
  }
}

@media screen and (max-width: 640px) {
  .banner_content {
    position: relative;
    text-align: center;
    background: white;
    height: 165px !important;
    padding: 0 10px;
    width: 85%;
  }

  .blog {
    font-size: 17px;
    text-align: justify;
  }

  .home-main-div .blog-div .blog-desc {
    font-size: 17px;
    text-align: justify;

  }

  .home-main-div .blog-div .blog-desc h5 {
    font-size: 17px;
    text-align: justify;
    margin-top: 2px;

  }

  .home-main-div .contents-div .blog-div ul>li {
    font-size: 17px;
    /* text-align: justify; */

  }

  /* .banner_content h1 {
    font-size: 45px;
  } */
  .banner_content h1 {
    font-size: 30px;
  }

  /* .banner_content .butn-style4 {
    padding: 10px 24px;
    margin-top: 20px !important;
  } */
  .banner_content .butn-style4 {
    padding: 10px 24px;
    margin-bottom: 10px !important;

  }

  .banner_main-div .banner_images {
    height: 400px !important;
  }

  .banner-main-div .banner-img {
    height: 375px !important;
  }

  /* .banner_content {
    height: 400px !important;
  } */
  /* .banner_content {
    height: 165px !important;
  } */

  .home-main-div {
    padding: 0 9px !important;
  }

  .home-main-div .single-title {
    font-size: 2.35rem !important;
  }

  .home-main-div .content-title {
    font-size: 1.8rem !important;
  }

  .home-main-div .content-div .image-div .image {
    width: 100% !important;
  }

  form {
    width: unset;
    min-width: 100px;
    min-height: 400px;
    padding: 20px 20px 40px 20px;
    margin: 0 10px;
  }

  .footer-main-div .footer-inner-div h4 {
    font-size: 1.4rem !important;
  }

  .footer-main-div .footer-inner-div h6 {
    font-size: 1rem !important;
  }

  .about-us-main-div .about-us-img {
    height: 200px;
  }

  .about-us-main-div .about-inner-div {
    margin: 1rem 0 !important;
    padding: 0 !important;
  }

  .about-us-main-div .about-content {
    padding: 0 15px !important;
  }

  .about-us-main-div .about-us-img {
    height: 200px;
  }

  .footer-main-div .footer-inner-div .links-div {
    width: 50% !important;
  }

  .footer-main-div .inner-div {
    margin: 0 20px !important;
    /* width: 100% !important; */
  }

  .feedback-main-div {
    height: unset !important;
    padding-bottom: 15px !important;
  }

  .footer-logo {
    display: none;
    margin-top: 0 !important;
  }

  .second-footer-div {
    flex-direction: column;
    justify-content: center !important;
  }

  .footer-logo {
    display: none;
  }

  .link {
    margin-bottom: 5px !important;
    font-size: 0.9rem;
  }

  .all-content {
    padding: 0 !important;
  }

  .about-us-main-div .about-content {
    line-height: 33px;
    font-size: 1rem;
    padding: 0 !important;
  }

  .footer-main-div .second-footer-div {
    margin: 0 !important;
  }

  .feedback_div {
    font-size: 21px !important;
    margin-bottom: 1rem !important;
  }

  .error_404 {
    font-size: 27px;
  }

  .content_404 {
    font-size: 16px;
    text-align: center;
  }
  .goBack_button{
    font-size: 18px;
  }
  .slick-prev:before, .slick-next:before{
    display: none !important;
  }
}
